<template>
  <div>
    <emails-base
        :mail-folders="mailsFolders"
        :selected-folder.sync="selectedFolder"

        @getEmails="getEmails"
    />
  </div>
</template>

<script>
import { ref, computed, watch, onUnmounted } from '@vue/composition-api'

import EmailsBase             from '../../components/email/v2/EmailsBase.vue'
import { clone, getUserData } from '../../utils/utils'
import useAPI                 from '../../utils/useAPI'
import { useEmails }          from '../../components/email/v2/useEmails'
import store                  from '../../store'

export default {
  components: {
    EmailsBase
  },
  props: {},
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const mailsFolders = ref([])
    const selectedFolder = ref({})
    const previousGetEmailsParams = ref(null)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const { refreshEmails } = useEmails()
    const {
      fetchMailFolders,
      fetchAutocompleteUsers,
      fetchAutocompleteWorkFlos,
      fetchAutocompleteWorkflows,
      fetchEmails
    } = useAPI()

    let interval = null
    const getMailFolders = () => {
      fetchMailFolders({
        filters: [
          {
            sectionLogicOperator: 'AND',
            sectionFilters: [
              {
                logicOperator: 'AND',
                field: 'owner',
                operator: 'equal',
                value: getUserData().id
              }
            ]
          }
        ]
      }).then(response => {
        // console.log(response)

        if (response.results.length == 0) {
          console.log('Refresh from emails')
          refreshEmails()
              .then(response => {
                getMailFolders()
              })
        } else {
          mailsFolders.value = response.results

          fetchAutocompleteUsers()
          fetchAutocompleteWorkflows()
          fetchAutocompleteWorkFlos()

          refreshEmailLocal()
          interval = setInterval(() => {
            if (store.getters['app/emailLoaders'] == 0) {
              refreshEmailLocal()
            }
          }, 2 * 60000)

          response.results.forEach(f => {
            if (f.title == 'Boîte de réception') {
              selectedFolder.value = {
                email: getUserData().email,
                folderId: f.id
              }
            }
          })
        }

      })
    }

    const refreshEmailLocal = () => {
      // console.log('Refresh from emails')
      refreshEmails()
          .then(response => {
            // console.log(response)
            if (
                response != false &&
                response.newEmails.length > 0
            ) {
              getEmails(previousGetEmailsParams.value)
            }
          })
    }

    const getEmails = (params) => {
      previousGetEmailsParams.value = clone(params)
      // console.log(params)

      fetchEmails(params.payload).then(r => {
        if (params.refreshAfter) {
          refresh()
        }
      })
    }

    const refresh = () => {
      console.log('Refresh from emails')
      refreshEmails()
          .then(response => {
            // console.log(response)
            // getMailFolders()
            getEmails()
          })
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------
    onUnmounted(() => {
      clearInterval(interval)
    })

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    getMailFolders()

    // if (getUserData().roles.includes('ROLE_ADMIN')) {
    //   interval = setInterval(() => {
    //     console.log('refresh emails', localStorage.getItem('isRefreshEmail'))
    //   }, 1 * 15000)
    // }

    return {
      // Components

      // Data
      mailsFolders,
      selectedFolder,

      // Computed

      // Methods
      getEmails,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>