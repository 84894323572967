<template>
  <div
      class="px-1 py-50 mb-1 cursor-pointer bg-white rounded-right text-nowrap text-truncate"
      :class="{'border-left-3 border-left-primary font-weight-bolder':!email.isRead, 'rounded-left': email.isRead, 'text-primary': isEmailHover&&!isSelected, 'bg-primary bg-lighten-5': isSelected}"
      style="line-height: 20px;"

      @mouseover="isEmailHover = true"
      @mouseleave="isEmailHover = false"
  >
    <!--    <div class="d-flex justify-content-between">-->
    <div class="w-100 d-flex flex-row t">
      <div class="flex-grow-1 text-nowrap text-truncate">
        <div class="d-flex justify-content-between">
          <div class="text-nowrap text-truncate">{{ displayName }}</div>
          <div class="d-flex">
            <div
                v-if="isEmailHover"
                class="d-flex text-secondary"
            >
              <!--            <div class="d-flex text-secondary">-->
              <div
                  v-b-tooltip.hover.bottom="email.isRead?$t('Mark as unread'):$t('Mark as read')"
                  class="px-50"
                  :class="{'text-primary': isReadButtonHover}"

                  @mouseover="isReadButtonHover = true"
                  @mouseleave="isReadButtonHover = false"
                  @click.prevent.stop="$emit('toggleRead')"
              >
                <icon
                    v-if="!email.isRead"
                    icon="envelope-open"
                />
                <icon
                    v-else
                    icon="envelope"
                />
              </div>
              <div
                  v-b-tooltip.hover.bottom="email.isFlagged?$t('Unmark this message'):$t('Mark this message')"
                  class="px-50"
                  :class="{'text-primary': isFlagButtonHover}"

                  @mouseover="isFlagButtonHover = true"
                  @mouseleave="isFlagButtonHover = false"
                  @click.prevent.stop="$emit('toggleFlag')"
              >
                <icon
                    v-if="!email.isFlagged"
                    icon="flag"
                    library="far"
                />
                <icon
                    v-else
                    class="text-danger"
                    icon="flag"
                />
              </div>
            </div>

            <div
                v-if="email.isFlagged && !isEmailHover"
                class="text-danger pl-2"
            >
              <icon icon="flag"/>
            </div>
            <div
                v-if="email.importance == 'high'"
                class="text-danger pl-2"
            >
              <icon icon="exclamation"/>
            </div>
            <div
                v-if="email.hasAttachments"
                class="text-info"
                :class="email.importance == 'high'?'pl-50':'pl-2'"
            >
              <icon icon="paperclip"/>
            </div>
          </div>


        </div>

        <div class="d-flex justify-content-between">
          <div class="text-nowrap text-truncate">
            {{ email.subject }}
          </div>

          <div class="pl-2">
            {{ displayReceivedDateTime }}
          </div>
        </div>

        <div class="text-secondary font-weight-normal text-truncate">
          {{ email.bodyPreview }}
        </div>

        <div
            v-if="email.hasAttachments"
            :v-b-tooltip.hover.bottom="email.attachments[0].name + '.' + email.attachments[0].entension"
            class="d-flex mt-50"
        >
          <div class="border text-truncate p-50 d-flex">
            <div class="mr-50">
              <icon
                  :icon="attachmentIcon(email.attachments[0]).icon"
                  size="lg"
                  :library="attachmentIcon(email.attachments[0]).library"
              />
            </div>
            <div class="text-truncate">
              {{ email.attachments[0].name + '.' + email.attachments[0].entension }}
            </div>
          </div>

          <div
              v-if="email.attachments.length > 1"
              class="my-auto pl-1"
          >
            + {{ email.attachments.length - 1 }}
          </div>
        </div>
      </div>
      <div
          v-if="isEmailHover"
          class="my-auto"
      >
        <trash-button
            @click.native.stop="$emit('delete')"
            :withIcon="true"
            :withText="false"
            :withBorder="false"
            size="sm"
            class="ml-1"
        />
      </div>
    </div>

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import moment                   from 'moment'
import TrashButton              from '../../../button/TrashButton.vue'

export default {
  components: { TrashButton },
  props: {
    email: {
      type: Object,
      default: () => {}
    },
    isSelected: {
      type: Boolean,
      default: false
    },
    displayToAddresses: {
      tye: Boolean,
      default: false
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const isEmailHover = ref(false)
    const isReadButtonHover = ref(false)
    const isFlagButtonHover = ref(false)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const displayName = computed(() => {
      if (props.displayToAddresses) {
        let names = []
        props.email.toRecipients.forEach(t=> {
          if (t.emailAddress.name != null) {
            names.push(t.emailAddress.name)
          } else {
            names.push(t.emailAddress.address)
          }
        })
        return names.join(', ')
      } else {
        return props.email.fromRecipient.emailAddress.name
      }
    })

    const displayReceivedDateTime = computed(() => {
      let receivedDateTimeMoment = moment(props.email.receivedDateTime)

      if (moment().diff(receivedDateTimeMoment, 'days') == 0) {
        return receivedDateTimeMoment.format('HH:mm')
      } else if (moment().diff(receivedDateTimeMoment, 'days') < 7) {
        return receivedDateTimeMoment.format('ddd HH:mm')
      } else if (receivedDateTimeMoment >= moment().subtract(1, 'months')) {
        return receivedDateTimeMoment.format('DD/MM')
      } else {
        return receivedDateTimeMoment.format('DD/MM/YYYY')
      }
    })

    // const displayedAttachment = computed(() => {
    //
    //   return props.email.attachments.length?props.email.attachments[0]:null
    // })

    // const attachmentIcon = computed(() => {
    //   if (email.extension == 'pdf') return 'file-pdf'
    //   else if (['jpg', 'png'].includes(email.extension)) return 'file-image'
    //   else if (email.extension == 'xlsx') return 'file-excel'
    //   else return 'file'
    // })
    //
    // const attachmentLibraryLocal = computed(() => {
    //   if (email.extension == 'pdf') return 'far'
    //   else return 'fas'
    // })
    //
    // const attachmentTooltip = computed(() => {
    //   console.log(JSON.parse(JSON.stringify(email)))
    //   return email.name + '.' + email.extension
    // })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const attachmentIcon = attachment => {
      if (attachment != null) {
        let output = {
          icon: '',
          library: 'far'
        }

        if (attachment.extension == 'pdf') {
          output.icon = 'file-pdf'
          output.library = 'fas'
        } else if (['jpg', 'png'].includes(attachment.extension)) output.icon = 'file-image'
        else if (attachment.extension == 'xlsx') output.icon = 'file-excel'
        else output.icon = 'file'

        return output
      }
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data
      isEmailHover,
      isReadButtonHover,
      isFlagButtonHover,

      // Computed
      displayName,
      displayReceivedDateTime,

      // Methods
      attachmentIcon
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>