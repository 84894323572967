<template>
  <div>
    <div
        class="bg-white rounded px-1 py-50 font-weight-bolder mb-2"
    >
      {{ email.subject }}
    </div>

    <email-view
        v-for="parent in linkedEmailsAfter"
        :email="parent"
        :read-only="true"
        :allow-collapse="true"
        class="mb-2"

        @reply="$emit('reply', parent)"
        @replyAll="$emit('replyAll', parent)"
        @forward="$emit('forward', parent)"
    />

    <email-view
        :email="email"
        :read-only="readOnly"

        @reply="$emit('reply', email)"
        @replyAll="$emit('replyAll', email)"
        @forward="$emit('forward', email)"
    />

    <email-view
        v-for="child in linkedEmailsBefore"
        :email="child"
        :read-only="true"
        :allow-collapse="true"
        class="mt-2"

        @reply="$emit('reply', child)"
        @replyAll="$emit('replyAll', child)"
        @forward="$emit('forward', child)"
    />

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import EmailView                from './EmailView.vue'

export default {
  components: {
    EmailView
  },
  props: {
    email: {
      type: Object,
      default: () => {}
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    displayChilds: {
      type: Boolean,
      default: true
    }
  },
  setup (props, { emit }) {
    // console.log(props.email)
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const linkedEmailsBefore = computed(() => {
      if ('_linkedEmails' in props.email) {
        return props.email._linkedEmails.before
      } else {
        return []
      }
    })
    const linkedEmailsAfter = computed(() => {
      if ('_linkedEmails' in props.email) {
        return props.email._linkedEmails.after
      } else {
        return []
      }
    })
    // const childs = computed(() => {
    //   let output = []
    //
    //   if ('childs' in props.email) {
    //     recursiveAddChild(output, props.email.childs)
    //   }
    //
    //   return output
    // })
    //
    // const recursiveAddChild = (output, arrayChilds) => {
    //   arrayChilds.forEach(c => {
    //     output.push(c)
    //       recursiveAddChild(output, c.childs)
    //   })
    // }

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data

      // Computed
      linkedEmailsBefore,
      linkedEmailsAfter,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>