<template>
  <div>
    <div class="bg-white rounded px-1 py-50">

      <validation-observer
          ref="observerRef"
          #default="{handleSubmit, errors}"
      >
        <email-form
            :email="newEmail"
        />

        <div class="my-2 d-flex">
          <b-button
              variant="gradient-primary"
              size="sm"

              @click.prevent="handleSubmit(send)"
          >
            {{ capitalize($t('send')) }}
          </b-button>

          <cancel
              class="ml-1"
              size="sm"

              @click="cancel"
          />
        </div>
      </validation-observer>

      <email-view
          v-if="newEmail.inReplyTo"
          :email="newEmail.inReplyTo"
          :read-only="true"
          class="mt-2"
      />

    </div>
  </div>
</template>

<script>
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

import { ref, computed, watch, onUpdated } from '@vue/composition-api'
import { capitalize }                      from '../../../../utils/filter'
import { quillEditor }                     from 'vue-quill-editor'
import { VueAutosuggest }                  from 'vue-autosuggest'
import api                                 from '../../../../libs/axios'
import Select2                             from '../../../input/Select2.vue'
import InputField                          from '../../../input/Input.vue'
import i18n                                from '../../../../libs/i18n'
import Cancel                              from '../../../button/Cancel.vue'
import DocumentCard                        from '../../../card/Document.vue'
import EmailView                           from './EmailView.vue'
import useAPI                              from '../../../../utils/useAPI'
import SelectEmail                         from '../../../input/custom/SelectEmail.vue'
import EmailForm                           from '../../../form/email/EmailForm.vue'
import { ValidationObserver }              from 'vee-validate'

export default {
  components: {
    EmailForm,
    SelectEmail,
    EmailView,
    Cancel,
    Select2,
    InputField,
    quillEditor,
    DocumentCard,
    VueAutosuggest,
    ValidationObserver
  },
  props: {
    newEmail: {
      type: Object,
      default: () => {}
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const toLocal = ref([])
    const ccLocal = ref([])
    const bccLocal = ref([])
    const isCcFieldHover = ref(false)
    const isBccFieldHover = ref(false)
    const isCcInputShowed = ref(false)
    const isBccInputShowed = ref(false)
    const htmlBody = ref('')
    const editorOptions = ref({
      modules: {
        // toolbar: '#myToolbar'
        toolbar: [
          [{ 'size': [] }],
          ['bold', 'italic', 'underline', 'strike'],
          [{ 'color': [] }, { 'background': [] }],
          [{ 'script': 'super' }, { 'script': 'sub' }],
          [{ 'header': '1' }, { 'header': '2' }, 'blockquote', 'code-block'],
          [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
          [{ 'align': [] }],
          ['link', 'image', 'video']
        ],
      },
      placeholder: capitalize(i18n.t('message'))
    })
    const isOverlay = ref(false)
    const fileRecords = ref([])
    const uploadHeaders = ref({
      Authorization: 'Bearer ' + localStorage.getItem('accessToken')
    })
    const apiURL = ref(api.defaults.baseURL + 'documents')
    const vueFileAgent = ref(null)
    const fileId = ref(null)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { autocompleteUsers } = useAPI()

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(toLocal, val => {
      // console.log(val)
      props.newEmail.toRecipients = []
      val.forEach(emailString => props.newEmail.toRecipients.push(
              {
                emailAddress: getMailAddressName(emailString)
              }
          )
      )

      // console.log(props.newEmail)
    })
    watch(ccLocal, val => {
      props.newEmail.ccRecipients = []
      val.forEach(emailString => props.newEmail.ccRecipients.push(
              {
                emailAddress: getMailAddressName(emailString)
              }
          )
      )
    })
    watch(bccLocal, val => {
      props.newEmail.bccRecipients = []
      val.forEach(emailString => props.newEmail.bccRecipients.push(
              {
                emailAddress: getMailAddressName(emailString)
              }
          )
      )
    })
    watch(htmlBody, val => {
      props.newEmail.body.content = val
    })

    watch(fileRecords, () => {
      if (fileRecords.value.length && !('id' in fileRecords.value[0])) {
        let base64 = null
        let reader = new FileReader()
        reader.readAsDataURL(fileRecords.value[0].file)
        reader.onload = () => {
          base64 = reader.result
        }

        vueFileAgent.value.upload(
            apiURL.value,
            uploadHeaders.value,
            [fileRecords.value.at(-1)],
            (fileData) => {
              // console.log(fileData.file)
              let formData = new FormData()
              formData.append('type', props.documentType)
              formData.append('file', fileData.file)
              return formData
            }
        )
            .then(response => {
              let singleFile = response[0].data
              singleFile._base64 = base64

              props.newEmail.attachments.push(response[0].data)

              isOverlay.value = false
              fileRecords.value.forEach(f => vueFileAgent.value.deleteFileRecord(f))
            })
            .catch(e => {
              console.log(e)
              isOverlay.value = false
              fileRecords.value.forEach(f => vueFileAgent.value.deleteFileRecord(f))
            })
      } else {
        // Delete file from edited source on put
        // emit('update:singleFile', null)
      }
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const getMailAddressName = (emailString) => {
      return {
        // emailAddress: {
        name: '',
        address: emailString
      }
      // }
    }

    const send = () => {
      emit('send', props.newEmail)
    }

    const cancel = () => {
      emit('cancel')
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    let tmpTo = []
    props.newEmail.toRecipients.forEach(r => tmpTo.push(r.emailAddress.address))
    if (tmpTo.join(',') != toLocal.value.join(',')
    ) {
      toLocal.value = tmpTo
    }
    htmlBody.value = props.newEmail.body.content

    onUpdated(x => {
      // console.log('Updated !', props.newEmail)

      // toRecipients
      let tmpTo = []
      props.newEmail.toRecipients.forEach(r => tmpTo.push(r.emailAddress.address))
      if (tmpTo.join(',') != toLocal.value.join(',')) {
        toLocal.value = tmpTo
      }

      // ccRecipients
      let tmpCc = []
      props.newEmail.ccRecipients.forEach(r => tmpCc.push(r.emailAddress.address))
      if (tmpCc.join(',') != ccLocal.value.join(',')) {
        ccLocal.value = tmpCc
      }

      // bccRecipients
      let tmpBcc = []
      props.newEmail.bccRecipients.forEach(r => tmpBcc.push(r.emailAddress.address))
      if (tmpBcc.join(',') != bccLocal.value.join(',')) {
        bccLocal.value = tmpCc
      }

      // body
      if (props.newEmail.body.content != htmlBody) {
        htmlBody.value = props.newEmail.body.content
      }
    })

    return {
      // Components

      // Data
      toLocal,
      ccLocal,
      bccLocal,
      isCcFieldHover,
      isBccFieldHover,
      isCcInputShowed,
      isBccInputShowed,
      htmlBody,
      editorOptions,
      isOverlay,
      fileRecords,
      vueFileAgent,
      fileId,

      // Computed
      autocompleteUsers,

      // Methods
      send,
      cancel,
    }
  },
  data () {
    return {}
  }
  ,
  computed: {}
  ,
  watch: {}
  ,
  methods: { capitalize }
  ,
  mounted () {
  }
  ,
  created () {
  }
}
</script>

<style
    lang="scss"
>
.email-new {
  .ql-toolbar {
    border-radius: 0 0 0.357rem 0.357rem;
  }

  .ql-container {
    border-radius: 0.357rem 0.357rem 0 0;
    border-bottom: 0;
    height: 250px;
  }

  .ql-toolbar.ql-snow + .ql-container.ql-snow {
    border-top: 1px solid #ccc;
  }

  .btn:hover {
    transition: all 0.2s ease;

    &:hover {
      transform: translateY(-2px);
    }
  }

  .b-overlay {
    div:nth-child(2) {
      width: 100%;
      height: 100%;
    }

    .file-input-wrapper {
      height: 100%;

      div {
        //height: 100%;
        position: relative;

        div {
          top: 33%;
        }


        .file-preview-wrapper {
          //top:33%;
        }
      }
    }
  }
}
</style>