<template>
  <div class="mr-2">
    <div
        class="d-flex"
    >
      <new-email-button
          :with-icon="true"
          size="sm"
          class="my-auto w-100"

          @click="$emit('newEmail')"
      />
    </div>

    <div
        class="mt-2"
        style="max-width: 200px"
    >
      <div
          v-for="(emailAndFolders, i) in emailsAndFolders"
          :key="'emailAndFolder_'+i"
      >
        <div class="text-truncate">
          <b class="text-nowrap2">{{ emailAndFolders.email }}</b>
        </div>

        <vue-tree-list
            :model="tree"
            default-tree-node-name="new node"
            default-leaf-node-name="new leaf"
            v-bind:default-expanded="false"

            @click="selectNode"
        >
          <template v-slot:leafNameDisplay="slotProps">
            <div class="d-flex justify-content-between">
              <div
                  class="text-truncate px-50 font-small-2 my-auto"
                  :class="{'text-primary font-weight-bolder': slotProps.model.id == selectedFolderId}"
              >{{ slotProps.model.name }}
              </div>
              <div v-if="slotProps.model.badge != 0" class="d-flex">
                <b-badge
                    pill
                    size="sm"
                    variant="light-primary"
                    class="my-auto"
                >{{ slotProps.model.badge }}
                </b-badge>
              </div>
            </div>
          </template>
          <span
              class="icon"
              slot="addTreeNodeIcon"
          >📂
          </span>
          <span
              class="icon"
              slot="addLeafNodeIcon"
          >＋
          </span>
          <span
              class="icon"
              slot="editNodeIcon"
          >📃
          </span>
          <span
              class="icon"
              slot="delNodeIcon"
          >✂️
          </span>
          <span
              class="icon"
              slot="leafNodeIcon"
          >🍃
          </span>
          <template v-slot:treeNodeIcon="slotProps">
            <span
                class="icon pr-50"
                :style="{ paddingLeft: (slotProps.model.children && slotProps.model.children.length > 0)?0:7+'px'}"
            >
              <icon
                  v-if="slotProps.model.name =='Boîte de réception'"
                  icon="box"
              />
              <icon
                  v-else-if="slotProps.model.name =='Éléments envoyés'"
                  icon="paper-plane"
              />
              <icon
                  v-else
                  icon="folder"
              />
            </span>
          </template>

        </vue-tree-list>

      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch }        from '@vue/composition-api'
import { VueTreeList, Tree, TreeNode } from 'vue-tree-list'
import Ripple                          from 'vue-ripple-directive'
import NewEmailButton                  from '../../../button/NewEmailButton.vue'

export default {
  directives: {
    Ripple,
  },
  components: { NewEmailButton, VueTreeList },
  props: {
    emailsAndFolders: {
      type: Array,
      default: () => []
    },
    selectedFolderId: {
      type: null | Number,
      default: null
    },
    mailFolders: {
      type: Array,
      default: () => []
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const tree = computed(() => {
      let tree = []
      props.mailFolders.forEach(f => {
        let children = []

        if (
            (
                f.title != 'Courrier indésirable' ||
                (
                    f.title == 'Courrier indésirable' && f.parentId != null
                )
            ) &&
            (
                f.title != 'Éléments supprimés' ||
                (
                    f.title == 'Éléments supprimés' && f.parentId != null
                )
            )
        ) {
          populateTree(f, children, 1)
        }

        tree.push({
          name: f.title,
          email: props.emailsAndFolders[0].email,
          id: f.id,
          badge: f.badge,
          pid: 0, // Level
          dragDisabled: true,
          disabled: true,
          children: children
        })
      })

      return new Tree(tree)
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    // watch(() => props.emailsAndFolders, val => {
    //   console.log(val)
    // }, { deep: true })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const populateTree = (folder, children, level) => {
      let currentLevel = JSON.parse(JSON.stringify(level))

      folder.subFolders.forEach(f => {
        let currentChildren = []
        populateTree(f, currentChildren, ++level)

        children.push({
          name: f.title,
          email: props.emailsAndFolders[0].email,
          id: f.id,
          badge: f.badge,
          pid: currentLevel, // Level
          dragDisabled: true,
          disabled: true,
          children: currentChildren
        })
      })
    }

    const selectNode = (node) => {
      emit('selectFolder', { email: node.email, folderId: node.id })
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data

      // Computed
      tree,

      // Methods
      selectNode,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>