<template>
  <div>
    <div>
      <!--      Search-->
      <div class="d-none d-md-block my-auto w-100 mr-2">
        <b-input-group
            class="input-group-merge"
            size="sm"
        >
          <b-form-input
              :placeholder="$t('Search')"
              class="search-product"
              v-model="localSearchQuery"
          />
          <b-input-group-append is-text>
            <icon
                icon="search"
                class="text-muted"
            />
          </b-input-group-append>
        </b-input-group>
      </div>
    </div>

    <div
        class="d-flex"
        style="height: 38.8px"
    >
      <div
          class="my-auto cursor-pointer mr-50"
          :class="{'text-primary': isTogglerLeftSidebarHover}"

          @mouseover="isTogglerLeftSidebarHover = true"
          @mouseleave="isTogglerLeftSidebarHover = false"
          @click="$emit('toggleLeftSidebar')"
      >
        <icon
            icon="bars"
            size="lg"
        />
      </div>

      <!--      <div class="my-auto">-->
      <!--        <b-form-checkbox-->
      <!--            v-model="isSelectedMailsInputChecked"-->
      <!--            :indeterminate.sync="isSelectedMailsInputPartial"-->
      <!--            class="mx-50"-->
      <!--        >-->
      <!--        </b-form-checkbox>-->
      <!--      </div>-->


      <div
          v-if="displayClassification"
          class="ml-50 my-auto cursor-pointer"
          :class="{'text-primary': isTogglerPriorityViewHover}"

          @mouseover="isTogglerPriorityViewHover = true"
          @mouseleave="isTogglerPriorityViewHover = false"
          @click="isOtherView=false;isPriorityView=true;selectedClassificationLocal='focused'"
      >
        <b
            class="pb-50"
            :class="{'border-bottom-primary border-bottom-3':isPriorityView}"
        >
          {{ capitalize($t('priorityR')) }}
        </b>
      </div>

      <div
          v-if="displayClassification"
          class="ml-1 my-auto cursor-pointer"
          :class="{'text-primary': isTogglerOtherViewHover}"

          @mouseover="isTogglerOtherViewHover = true"
          @mouseleave="isTogglerOtherViewHover = false"
          @click="isPriorityView=false;isOtherView=true;selectedClassificationLocal='other'"
      >
        <b
            class="pb-50"
            :class="{'border-bottom-primary border-bottom-3':isOtherView}"
        >
          {{ capitalize($t('other')) }}
        </b>
      </div>


    </div>

    <div
        class="mt-1 h-view overflow-y-scroll"

        @scroll="handleScroll"
    >
      <email-card
          v-for="(email,i) in displayedEmail"
          :key="'email_'+i"
          :email="email"
          :is-selected="selectedEmailId!=null&&email.id==selectedEmailId"
          :display-to-addresses="displayToAddresses"
          class="mr-1"

          @click.native="$emit('selectEmail', email)"
          @toggleRead="$emit('toggleRead', email)"
          @toggleFlag="$emit('toggleFlag', email)"
          @delete="$emit('deleteEmail', email)"
      />
    </div>
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize }           from '../../../../utils/filter'
import EmailCard                from './EmailCard.vue'
import store                    from '../../../../store'

export default {
  components: { EmailCard },
  props: {
    emails: {
      type: Array,
      default: () => []
    },
    selectedEmailId: {
      type: Number,
      default: null
    },
    selectedClassification: {
      type: String,
      default: 'focused'
    },
    displayClassification: {
      type: Boolean,
      default: true
    },
    displayToAddresses: {
      tye: Boolean,
      default: false
    },
    searchQuery: {
      type: String,
      default: ''
    }
  },
  setup (props, { emit }) {
    // console.log(props.displayToAddresses)
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const isTogglerLeftSidebarHover = ref(false)
    const isSelectedMailsInputChecked = ref(false)
    const isSelectedMailsInputPartial = ref(false)
    const isTogglerPriorityViewHover = ref(false)
    const isTogglerOtherViewHover = ref(false)
    const isPriorityView = ref(true)
    const isOtherView = ref(false)
    const scrollPositionBefore = ref(0)
    const localSearchQuery = ref(props.searchQuery)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const focusedEmails = computed(() => {
      return props.emails.filter(email => email.classification == 'focused')
    })

    const unFocusedEmails = computed(() => {
      return props.emails.filter(email => email.classification != 'focused')
    })

    const displayedEmail = computed(() => {
      if (isPriorityView.value) {
        return focusedEmails.value
      } else {
        return unFocusedEmails.value
      }
    })
    const selectedClassificationLocal = computed({
      get () {
        return props.selectedClassification
      },
      set (val) {
        emit('update:selectedClassification', val)
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    // watch(() => props.emails, e => {
    //   console.log(e)
    // }, {deep: true})

    let delayTimer
    watch(localSearchQuery, val => {
      clearTimeout(delayTimer)
      delayTimer = setTimeout(() => {
        emit('update:searchQuery', val)
      }, 500)
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const handleScroll = (evt) => {
      let heightMax = document.getElementsByClassName('h-view')[0].scrollHeight - document.getElementsByClassName('h-view')[0].clientHeight

      if (
          evt.target.scrollTop >= 0.7 * heightMax &&
          evt.target.scrollTop > scrollPositionBefore.value &&
          !store.getters['app/isLoading']
      ) {
        // console.log('>= ' + 0.7 * heightMax)
        // console.log('emit nextPage')
        emit('nextPage')
      }
      scrollPositionBefore.value = evt.target.scrollTop
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data
      isTogglerLeftSidebarHover,
      isSelectedMailsInputChecked,
      isSelectedMailsInputPartial,
      isTogglerPriorityViewHover,
      isTogglerOtherViewHover,
      isPriorityView,
      isOtherView,
      localSearchQuery,

      // Computed
      displayedEmail,
      selectedClassificationLocal,

      // Methods
      handleScroll,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: { capitalize },
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>
.h-view {
  height: calc(100vh - 207px);
}
</style>