<template>
  <div class="email-base">
    <div>
      <b-row>
        <b-col :md="isLeftSidebarVisible?'5':'4'">
          <div
              class="d-md-flex"
          >
            <email-left-sidebar
                class="email-sidebar-left"
                :class="{'show': isLeftSidebarVisible}"
                :emails-and-folders="emailsAndFolders"
                :selected-folder-id="selectedFolderId"
                :mail-folders="mailFolders"

                @newEmail="newEmail"
                @selectFolder="selectFolder"
            />

            <email-list
                class="text-truncate"
                :emails="emailsList"
                :selected-email-id="selectedEmail.id"
                :selected-classification.sync="selectedClassification"
                :display-classification="displayClassification"
                :display-to-addresses="displayToAddresses"
                :search-query.sync="searchQuery"


                @toggleLeftSidebar="isLeftSidebarVisible = !isLeftSidebarVisible"
                @selectEmail="selectEmail"
                @toggleRead="toggleRead"
                @toggleFlag="toggleFlag"
                @deleteEmail="deleteEmail"
                @nextPage="nextPage"
            />


          </div>
        </b-col>
        <b-col :md="isLeftSidebarVisible?'7':'8'">
          <b-tabs
              end
              v-model="activeTab"
          >
            <b-tab
                v-for="(tab,i) in tabs"
                class="mr-1"
            >
              <!--              View-->
              <template #title>
                <div
                    v-if="tab.type == 'view'"
                    class="d-flex"
                >
                  <icon
                      icon="eye"
                      class="my-auto mr-50"
                  />
                  <span
                      style="width: 150px"
                      class="my-auto d-block text-truncate"
                  >{{ tab.title }}
                  </span>

                  <b-button
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="flat-danger"
                      size="sm"
                      class="my-auto p-50 ml-2"

                      @click.stop.prevent="tabs.splice(i,1); selectedEmail={id:null}"
                  >
                    <icon icon="times"/>
                  </b-button>
                </div>

                <div
                    v-else-if="tab.type == 'new email'"
                    class="d-flex"
                >
                  <icon
                      icon="pencil-alt"
                      class="my-auto mr-50"
                  />
                  <span
                      style="width: 150px"
                      class="my-auto d-block text-truncate"
                  >{{ capitalize($t('draft')) }}
                  </span>

                  <b-button
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="flat-danger"
                      size="sm"
                      class="my-auto p-50 ml-2"

                      @click.stop.prevent="closeTab(i)"
                  >
                    <icon icon="times"/>
                  </b-button>
                </div>

                <div
                    v-else-if="tab.type == 'reply email'"
                    class="d-flex"
                >
                  <icon
                      icon="reply"
                      class="my-auto mr-50"
                  />
                  <span
                      style="width: 150px"
                      class="my-auto d-block text-truncate"
                  >{{ tab.title }}
                  </span>

                  <b-button
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="flat-danger"
                      size="sm"
                      class="my-auto p-50 ml-2"

                      @click.stop.prevent="closeTab(i)"
                  >
                    <icon icon="times"/>
                  </b-button>
                </div>
              </template>

              <email-view-global
                  v-if="tab.type == 'view' && selectedEmail.id != null"
                  :key="'viewEmail_'+i"
                  :email="selectedEmail"

                  @reply="replyEmail"
                  @replyAll="replyAllEmail"
                  @forward="forwardEmail"
              />

              <!--New email-->
              <email-new
                  v-if="tab.type == 'new email'"
                  :key="'newEmail_'+i"
                  :new-email="tab.form"

                  @send="sendEmailLocal"
                  @cancel="closeTab(i)"
              />

              <!--Reply/forward email-->
              <email-new
                  v-if="tab.type == 'reply email'"
                  :key="'replyForwardEmail_'+i"
                  :new-email="tab.form"
                  class="mb-2"

                  @send="sendEmailLocal"
                  @cancel="closeTab(i);selectedEmail={id:null}"
              />
              <email-view-global
                  v-if="tab.type == 'reply email' && selectedEmail.id != null"
                  :key="'viewEmail_'+i"
                  :email="selectedEmail"

                  @reply="replyEmail"
                  @replyAll="replyAllEmail"
                  @forward="forwardEmail"
              />

            </b-tab>
          </b-tabs>

        </b-col>
      </b-row>
    </div>


    <!-- modal event-->
    <modal-event
        :event.sync="currentEvent"
        :title="$t('NewEvent')"
        :isOpen.sync="eventModalShow"

        @submitValidatedEvent="submitValidatedEventLocal"
    />
  </div>
</template>

<script>
import { ref, computed, watch, onBeforeUpdate }        from '@vue/composition-api'
import { capitalize }                                  from '../../../utils/filter'
import { useEmails }                                   from './useEmails'
import { useMS }                                       from '../../../utils/useMS'
import { clone, getUserData, isEmptyObject, isObject } from '../../../utils/utils'
import { getRoute }                                    from '../../../utils/utils'
import Ripple                                          from 'vue-ripple-directive'
import i18n                                            from '../../../libs/i18n'
import EmailLeftSidebar                                from './components/EmailLeftSidebar.vue'
import EmailList                                       from './components/EmailList.vue'
import EmailViewGlobal                                 from './components/EmailViewGlobal.vue'
import EmailView                                       from './components/EmailView.vue'
import EmailNew                                        from './components/EmailNew.vue'
import store                                           from '../../../store'
import useAPI                                          from '../../../utils/useAPI'
import ModalEvent                                      from '../../prompt/Event.vue'
import { useEvents }                                   from '../../eventsCalendar/useEvents'
import moment                                          from 'moment'

export default {
  directives: {
    Ripple,
  },
  components: { ModalEvent, EmailNew, EmailViewGlobal, EmailView, EmailList, EmailLeftSidebar },
  props: {
    mailFolders: {
      type: Array,
      default: () => []
    },
    selectedFolder: {
      type: Object,
      default: () => {}
    },
    displayClassification: {
      type: Boolean,
      default: true
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const isLeftSidebarVisible = ref(true)
    const selectedEmailAddress = ref(getUserData().email)
    const selectedFolderId = ref(null)
    const selectedEmail = ref({
      id: null
    })
    const selectedClassification = ref('focused')
    const tabs = ref([])
    const activeTab = ref(1)

    const emptyEmail = ref(clone(store.getters['email/getEmptyEmail']))
    const payloadEmails = ref({
      filters: [
        {
          sectionLogicOperator: 'AND',
          sectionFilters: [
            {
              logicOperator: 'AND',
              field: 'concernedUsers',
              operator: 'equal',
              value: getUserData().id
            },
            {
              logicOperator: 'AND',
              field: 'folder',
              operator: 'equal',
              value: selectedFolderId
            },
            {
              logicOperator: 'AND',
              field: 'classification',
              operator: 'equal',
              value: selectedClassification.value
            },
            {
              logicOperator: 'AND',
              name: '_emailGroupByConversation',
              field: '_emailGroupByConversation',
            }
          ]
        }
      ],
      page: 1,
      sorts: [{ column: 'receivedDateTime', order: 'DESC' }]
    })
    const isEmailsLoading = ref(false)
    const searchQuery = ref('')
    const currentEvent = ref({})
    const eventModalShow = ref(false)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { emails, emailsContext } = useAPI()

    const emailsAndFolders = computed(() => {
      let output = {
        email: getUserData().email,
        // folders: mailFolders.value
        folders: []
      }
      return [output]
    })

    const emailsList = computed(() => {
      // console.log(emails)
      return emails.value
    })

    const displayToAddresses = computed(() => {
      if (
          props.mailFolders !== undefined &&
          props.mailFolders.some(mf => mf.id == selectedFolderId.value) != false
      ) {
        // console.log(props.mailFolders)
        // console.log(props.mailFolders.find(mf => mf.id == selectedFolderId.value))
        return props.mailFolders.find(mf => mf.id == selectedFolderId.value).title == 'Éléments envoyés'
      } else {
        return false
      }

    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(() => tabs, val => {
      // console.log(val.value)
    }, { deep: true })

    watch(selectedFolderId, val => {
      payloadEmails.value.page = 1
      tabs.value = []

      emit(
          'update:selectedFolder',
          {
            email: getUserData().email,
            folderId: val
          }
      )

      getEmails(false)
    })
    watch(selectedClassification, val => {
      // console.log(val)
      let classificationPayloadIndex = payloadEmails.value.filters[0].sectionFilters.findIndex(sf => sf.field == 'classification')

      payloadEmails.value.filters[0].sectionFilters[classificationPayloadIndex].value = val
      getEmails()
    })

    watch(() => props.selectedFolder, val => {
      // console.log(val)
      if (selectedFolderId.value != val.folderId) {
        selectedFolderId.value = val.folderId
      }
    }, { deep: true })

    watch(searchQuery, val => {
      // console.log(val)

      if (payloadEmails.value.filters[0].sectionFilters[0].field == '_all') {
        payloadEmails.value.filters[0].sectionFilters[0].value = val
      } else {
        if (val == '') {
          payloadEmails.value.filters[0].sectionFilters = payloadEmails.value.filters[0].sectionFilters.filter(item => item.field != '_all')
        } else {
          payloadEmails.value.filters[0].sectionFilters.unshift({
            logicOperator: 'AND',
            field: '_all',
            value: val
          })
        }
      }

      getEmails()
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const {
      newEmailNotification,
      someEmailsNotification,
      recusiceAddEmail,
      sentEmailNotification,
      refreshEmails,
      sendEmail
    } = useEmails()
    const { login, selectAccount, logout } = useMS()
    const { fetchEmails, fetchMailFolders, fetchAutocompleteUsers } = useAPI()
    const { submitValidatedEvent } = useEvents()

    const newEmail = () => {
      let mail = clone(emptyEmail.value)

      mail.body.content = '<br /><br />' + getUserData().mailSignature
      tabs.value.push({
        type: 'new email',
        form: mail
      })

      currentEvent.value.startDate = moment()

      if (getRoute().name == 'WorkFlo view') {
        let opportunity = store.getters['workFlo/getWorkFlo'](getRoute().params.workFloId)

        mail.toRecipients = [
          {
            emailAddress: {
              name: opportunity.mainContact._display,
              address: opportunity.mainContact.email
            }
          }
        ]

        currentEvent.value.workFlo = opportunity
      }

      console.log('mailStart : ' + currentEvent.value.startDate.format('HH:mm:ss'))

      setTimeout(() => {
        activeTab.value++
      }, 100)
    }

    const selectFolder = ({ email, folderId }) => {
      // console.log(email, folderId)
      // console.log(props.mailFolders)
      //
      // console.log(props.mailFolders.find(mf => mf.id == folderId))

      selectedEmailAddress.value = email
      selectedFolderId.value = folderId

      // getEmails()
    }

    const selectEmail = email => {
      // Fetch !
      // console.log(email)
      selectedEmail.value = email
      selectedEmail.value.ccRecipients = []

      store.dispatch('email/getEmail', email.id)
          .then(response => {
            // console.log(response)
            selectedEmail.value = response.data
          })
          .catch(err => {
            if (err.response.data.message == 'MS access token fail') {
              login().then(response => {
                // console.log(response)
                selectEmail(email)
              })
            } else if (err.response.data.message == 'Email not found') {
              deleteEmail(email)

              let index = tabs.value.findIndex(i => i.type == 'view')
              if (index != -1) {
                tabs.value.splice(index, 1, {
                  type: 'view',
                  title: email.subject
                })
              }

            } else {
              console.log(console.log(err.response.data.message))
            }
          })

      let index = tabs.value.findIndex(i => i.type == 'view')

      if (index == -1) {
        tabs.value.push({
          type: 'view',
          title: email.subject
        })
      } else {
        tabs.value.splice(index, 1, {
          type: 'view',
          title: email.subject
        })
      }

      setTimeout(() => {
        activeTab.value++
      }, 100)
    }

    const closeTab = tabIndex => {
      tabs.value.splice(tabIndex, 1)
    }

    const getEmails = (refreshAfter = false) => {
      // console.log(clone(payloadEmails.value))
      emit('getEmails', {
        payload: payloadEmails.value,
        refreshAfter: refreshAfter
      })

      // isEmailsLoading.value = true
      // fetchEmails(payloadEmails.value).then(r => {
      //   isEmailsLoading.value = false
      //   if (refreshAfter) {
      //     refresh()
      //   }
      // })
    }

    const sendEmailLocal = email => {
      sendEmail(email)
          .then(response => {
            if (response.data.folder.id == selectedFolderId.value) {
              store.commit('email/ADD_EMAIL', response.data)
              store.commit('email/UPDATE_EMAILSCONTEXTTOTALRESULT', 'add')
            }

            store.dispatch('event3/getEvents', {
              // filters: dateFilter
              filters: [{
                sectionLogicOperator: 'AND',
                sectionFilters: [
                  {
                    logicOperator: 'AND',
                    field: 'startDate',
                    operator: 'contains',
                    value: moment().format('YYYY-MM-DD')
                  },
                ]
              },
                {
                  sectionLogicOperator: 'AND',
                  sectionFilters: [{
                    logicOperator: 'OR',
                    field: 'createdBy',
                    operator: 'equal',
                    value: getUserData().id
                  }, {
                    logicOperator: 'OR',
                    field: 'additionalMembers',
                    operator: 'equal',
                    value: getUserData().id
                  }
                  ]
                }
              ],
              per_page: 5000
            }).then(response => {

            })

            let endDate = moment()
            // console.log(endDate.diff(currentEvent.value.startDate, 'minutes'))
            if (endDate.diff(currentEvent.value.startDate, 'minutes') < 1) {
              endDate = moment(currentEvent.value.startDate)
              endDate = endDate.add(1, 'minutes')
            }

            currentEvent.value.title = email.subject
            currentEvent.value.endDate = endDate
            currentEvent.value.writtenEmail = response.data
            eventModalShow.value = true

            console.log('mailEnd : ' + currentEvent.value.endDate.format('HH:mm:ss'))
            console.log(clone(currentEvent.value))

            closeTab(activeTab.value)
            emptyEmail.value = clone(store.getters['email/getEmptyEmail'])
          })
    }

    // const sendEmail = (email) => {
    //   // console.log(clone(email))
    //   email.toRecipients.forEach((toRecipient, i) => {
    //     if (isObject(toRecipient.emailAddress.address)) {
    //       if (toRecipient.emailAddress.address.email != null) {
    //         email.toRecipients[i].emailAddress.address = toRecipient.emailAddress.address.email
    //       } else if (toRecipient.emailAddress.address._display != null) {
    //         email.toRecipients[i].emailAddress.address = toRecipient.emailAddress.address._display
    //       }
    //     }
    //   })
    //
    //   store.dispatch('email/addEmail', email)
    //       .then(response => {
    //         // console.log(response)
    //
    //         if (response.data.folder.id == selectedFolderId.value) {
    //           store.commit('email/ADD_EMAIL', reponse.data)
    //           store.commit('email/UPDATE_EMAILSCONTEXTTOTALRESULT', 'add')
    //         }
    //
    //         currentEvent.value.title = email.subject
    //         currentEvent.value.endDate = moment()
    //         eventModalShow.value = true
    //
    //         console.log(clone(currentEvent.value))
    //
    //         closeTab(activeTab.value)
    //         emptyEmail.value = clone(store.getters['email/getEmptyEmail'])
    //       })
    // }

    const replyEmail = (email) => {
      // console.log(email)
      // let replyEmail = clone(emptyEmail.value)
      // replyEmail.subject = 'Re : ' + selectedEmail.value.subject
      // replyEmail.parent = selectedEmail.value.id
      // replyEmail.toRecipients = [selectedEmail.value.fromRecipient]
      // replyEmail.body.content = '<br /><br />' + getUserData().mailSignature

      let replyEmail = clone(emptyEmail.value)
      replyEmail.subject = 'Re : ' + email.subject
      replyEmail.parent = email.id
      replyEmail.toRecipients = [email.fromRecipient]
      replyEmail.body.content = '<br /><br />' + getUserData().mailSignature

      currentEvent.value.startDate = moment()
      console.log('mailStart : ' + currentEvent.value.startDate.format('HH:mm:ss'))

      closeTab(activeTab)
      tabs.value.push({
        type: 'reply email',
        title: replyEmail.subject,
        form: replyEmail
      })
    }

    const replyAllEmail = (email) => {
      // let replyEmail = clone(emptyEmail.value)
      // replyEmail.subject = 'Re : ' + selectedEmail.value.subject
      // replyEmail.parent = selectedEmail.value.id
      // replyEmail.toRecipients = [selectedEmail.value.fromRecipient]
      // replyEmail.body.content = '<br /><br />' + getUserData().mailSignature
      // selectedEmail.value.toRecipients.forEach(to => {
      //   if (to.emailAddress.address != getUserData().email) {
      //     replyEmail.toRecipients.push(to)
      //   }
      // })

      let replyEmail = clone(emptyEmail.value)
      replyEmail.subject = 'Re : ' + email.subject
      replyEmail.parent = email.id
      replyEmail.toRecipients = [email.fromRecipient]
      replyEmail.body.content = '<br /><br />' + getUserData().mailSignature
      email.toRecipients.forEach(to => {
        if (to.emailAddress.address != getUserData().email) {
          replyEmail.toRecipients.push(to)
        }
      })

      currentEvent.value.startDate = moment()
      console.log('mailStart : ' + currentEvent.value.startDate.format('HH:mm:ss'))
// console.log(replyEmail)

      closeTab(activeTab)
      tabs.value.push({
        type: 'reply email',
        title: replyEmail.subject,
        form: replyEmail
      })
    }

    const forwardEmail = (email) => {
      // let forwardEmail = clone(emptyEmail.value)
      // console.log(forwardEmail)
      // forwardEmail.subject = 'Fw : ' + selectedEmail.value.subject
      // forwardEmail.isForward = true
      // forwardEmail.parent = selectedEmail.value.id
      // forwardEmail.body.content = '<br /><br />' + getUserData().mailSignature
// replyEmail.toRecipients = [selectedEmail.value.fromRecipient]

      // console.log(replyEmail)

      let forwardEmail = clone(emptyEmail.value)
      forwardEmail.subject = 'Fw : ' + email.subject
      forwardEmail.isForward = true
      forwardEmail.parent = email.id
      forwardEmail.body.content = '<br /><br />' + getUserData().mailSignature

      currentEvent.value.startDate = moment()
      console.log('mailStart : ' + currentEvent.value.startDate.format('HH:mm:ss'))

      closeTab(activeTab)
      tabs.value.push({
        type: 'reply email',
        title: forwardEmail.subject,
        form: forwardEmail
      })
    }

    // const getMailFolders = () => {
    //       return new Promise((resolve, reject) => {
    //         fetchMailFolders({
    //           filters: [
    //             {
    //               sectionLogicOperator: 'AND',
    //               sectionFilters: [
    //                 {
    //                   logicOperator: 'AND',
    //                   field: 'owner',
    //                   operator: 'equal',
    //                   value: getUserData().id
    //                 }
    //               ]
    //             }
    //           ]
    //         }).then(r => {
    //           resolve(true)
    //           // refresh()
    //           // if (r.results.length == 0) {
    //           //   refresh()
    //           // }
    //
    //           r.results.forEach(f => {
    //             if (f.title == 'Boîte de réception') {
    //               selectFolder({
    //                 email: getUserData().email,
    //                 folderId: f.id
    //               })
    //             }
    //           })
    //         })
    //       })
    // }

    const refresh = () => {
      refreshEmails()
          .then(response => {
            // console.log(response)
            // getMailFolders()
            getEmails()
          })
    }

    const nextPage = () => {
      if (!isEmailsLoading.value && payloadEmails.value.page != emailsContext.value.total_pages) {
        payloadEmails.value.page++
        getEmails()
      }
    }

    const deleteEmail = (email) => {
      // console.log(email)
      store.dispatch('email/removeEmail', email)
    }

    const toggleRead = (email) => {
      store.dispatch('email/getEmailWithoutReadUpdate', email.id)
          .then(response => {
            // console.log(response)
            let email = response.data
            email.isRead == true ? email.isRead = false : email.isRead = true

            store.dispatch('email/updateEmail', email)
          })
          .catch(response => {
            console.log(response)
          })
    }

    const toggleFlag = (email) => {
      store.dispatch('email/getEmailWithoutReadUpdate', email.id)
          .then(response => {
            // console.log(response)
            let email = response.data
            email.isFlagged == true ? email.isFlagged = false : email.isFlagged = true

            store.dispatch('email/updateEmail', email)
          })

    }

    const resetEvent = () => {
      currentEvent.value = clone(store.getters['event3/getEmptyEvent'])
      currentEvent.value.type = 'mail'
    }

    const submitValidatedEventLocal = () => {
      console.log(clone(currentEvent.value))
      submitValidatedEvent(currentEvent.value)
          .then(response => {
            eventModalShow.value = false
            resetEvent()
          })
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    if (!isEmptyObject(props.selectedFolder)) {
      selectedFolderId.value = props.selectedFolder.folderId
    }
    // getMailFolders()
    //     .then(() => {
    //       refreshEmails()
    //     })
    // fetchAutocompleteUsers()

    // console.log(getRoute())
    // if (getRoute().name == "WorkFlo view") {
    //   console.log(getRoute().params.workFloId)
    // }

    // setTimeout(() => {
    //   console.log('4 seconds')
    //   newEmailNotification({
    //     subject: 'RE: Bonne année ',
    //     bodyPreview: 'J\'ai transmis l\'élément à @Florian pour la mettre en place 🙂'
    //   })
    //   // sentEmailNotification()
    //   // emailsAndFolders.value[0].folders[0].badge++
    // }, '4000')
    // newEmail()

    resetEvent()

    return {
      // Components
      capitalize,

      // Data
      isLeftSidebarVisible,
      emailsAndFolders,
      selectedEmail,
      tabs,
      activeTab,
      selectedFolderId,
      selectedClassification,
      searchQuery,
      currentEvent,
      eventModalShow,

      // Computed
      emailsList,
      displayToAddresses,
      // mailFolders,

      // Methods
      newEmail,
      selectFolder,
      selectEmail,
      closeTab,
      sendEmailLocal,
      replyEmail,
      replyAllEmail,
      forwardEmail,
      nextPage,
      deleteEmail,
      toggleRead,
      toggleFlag,
      submitValidatedEventLocal,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style
    lang="scss"
>
.email-base {
  .email-sidebar-left {
    display: none;
    //-webkit-transform: translateX(-130%);
    //transform: translateX(-130%);
    //left: 0;
    //border-top-left-radius: 0.25rem;
    //border-bottom-left-radius: 0.25rem;
    //-webkit-transition: all .3s ease;
    //transition: all .3s ease;

    &.show {
      //-webkit-transform: translateX(0);
      //transform: translateX(0);
      //-webkit-transition: all .3s ease;
      //transition: all .3s ease;
      display: block;
    }
  }

  .nav-tabs .nav-link {
    padding: 0.61rem 0rem 0.61rem 0.5rem;
    //padding-right: 0.2rem;
  }
}

</style>