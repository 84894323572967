import store     from '@/store'
import { clone } from '../../utils/utils'
import moment    from 'moment'

export const useEvents = () => {
  const submitValidatedEvent = (event) => {
    return new Promise((resolve, reject) => {
      if ('id' in event) {
        updateEvent(event)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      } else {
        saveNewEvent(event)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      }
    })
  }

  const saveNewEvent = (event) => {
    return new Promise((resolve, reject) => {
      store.dispatch('event3/addEvent', event)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  const updateEvent = (event) => {
    return new Promise((resolve, reject) => {
      store.dispatch('event3/updateEvent', event)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  const removeEvent = (event) => {
    return new Promise((resolve, reject) => {
      store.dispatch('event3/removeEvent', event)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  const eventToReminder = (event) => {
    let reminder = clone(event)

    // let startDate = moment(clone(event.startDate))
    // let hours = startDate.format('H')
    // let minutes = startDate.format('m')

    delete reminder.id
    delete reminder.logs
    delete reminder.mileageAllowanceCost
    delete reminder.outgoingCall
    delete reminder.startDate
    delete reminder.endDate
    delete reminder.isAllDay
    delete reminder.isValidated
    delete reminder.reminder
    delete reminder.callResultType
    delete reminder.isOutgoingCall
    delete reminder.distance

    reminder.priority = 'low'
    // reminder.completionDate = moment(clone(event._reminderPeriod)).hour(hours).minute(minutes)
    reminder.completionDate = moment(clone(event._reminderPeriod)).format('YYYY-MM-DD')
    reminder.type = event._reminderType

    return reminder
  }

  return {
    submitValidatedEvent,
    removeEvent,
    eventToReminder,
  }
}